<template>
  <b-form-group
    v-bind="$attrs"
    v-on="$listeners"
    :label-for="labelFor ? labelFor : `input-${label}`"
  >
    <template #label>
      <div class="d-flex align-items-center">
        <span>{{ label }}</span>
        <AppQuestionTooltip
          v-if="tooltipTitle"
          :title="tooltipTitle"
          :description="tooltipDescription"
          :width="tooltipWidth"
          style="margin: 0px 5px"
        />
        <span v-if="required" class="text-danger">*</span>
      </div>
    </template>
    <slot></slot>
    <template v-for="(_, name) in $slots" #[name]>
      <slot :name="name"></slot>
    </template>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import AppQuestionTooltip from "@core/components/app-question-tooltip/AppQuestionTooltip.vue";

export default {
  name: "BFormGroupWithTooltip",
  components: {
    BFormGroup,
    AppQuestionTooltip,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    tooltipTitle: {
      type: String,
      default: "",
    },
    tooltipDescription: {
      type: String,
      default: "",
    },
    tooltipWidth: {
      type: String,
      default: "100%",
    },
    required: {
      type: Boolean,
      default: false,
    },
    labelFor: {
      type: String,
      default: "",
    },
  },
  inheritAttrs: false,
};
</script>
