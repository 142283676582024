
<div class="question-tooltip-wrapper">
  <feather-icon
    id="question-icon"
    icon="HelpCircleIcon"
    size="14"
    class="question-icon"
  />
  <b-tooltip target="question-icon" triggers="hover">
    <div
      :style="{
        padding: '10px',
        width: width,
        backgroundColor: '#383434',
        borderRadius: '8px',
      }"
    >
      <p
        v-if="title"
        style="
          font-weight: bold;
          margin-bottom: 6px;
          font-size: larger;
          text-align: left;
        "
      >
        {{ title }}
      </p>
      <p v-html="description" style="margin-bottom: 0; text-align: left"></p>
    </div>
  </b-tooltip>
</div>
