import Vue from "vue";
import { ToastPlugin, ModalPlugin, IconsPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import "@fullcalendar/core/vdom"; // import * as VueGoogleMaps from "vue2-google-maps";
import "mapbox-gl/dist/mapbox-gl.css";
import * as Sentry from "@sentry/vue";
import BFormGroupWithTooltip from "@core/components/app-form-group/BFormGroupWithTooltip.vue";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import i18n from "@/libs/i18n";
import AppQuestionTooltip from "@core/components/app-question-tooltip/AppQuestionTooltip.vue";
// Global Components
import "./global-components";

// 3rd party plugins
import "axios";
// import '@/libs/acl'
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";
import { KanbanPlugin } from "@syncfusion/ej2-vue-kanban";
import { registerLicense } from "@syncfusion/ej2-base";
// Axios Mock Adapter

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
// Composition API
Vue.use(VueCompositionAPI);
Vue.use(KanbanPlugin);
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCfEx0RXxbf1x0ZFNMYFhbQH9PMyBoS35RckViW35ednRRQ2BfVU13"
);
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: import.meta.env.GOOGLE_MAPS_API_KEY,
//     libraries: "places", // Specify additional Google Maps libraries if needed
//   },
// });

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
import "@core/assets/fonts/feather/iconfont.css"; // For form-wizard

// import core styles
import "@core/scss/core.scss";

// import assets styles
import "@/assets/scss/style.scss";

import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

Vue.config.productionTip = false;
import { mapGetters, mapActions, mapMutations } from "vuex";

var mixin = {
  data() {
    return {
      baseUrl: "",
    };
  },
  computed: {
    ...mapGetters(["all_data", "createResponse"]),
    getUserInfo() {
      return JSON.parse(localStorage.getItem("userData")) || false;
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
      "getList",
      "clearCache",
      "create",
      "update",
      "delete",
      "patch",
      "getOne",
    ]),
  },
};

Sentry.init({
  Vue,
  dsn: "https://95adfb17eb2b78cfac1a45d36a6bd3f7@o4508216862769152.ingest.de.sentry.io/4508217582747728",
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Vue.component("AppQuestionTooltip", AppQuestionTooltip);
Vue.component("BFormGroupWithTooltip", BFormGroupWithTooltip);
Vue.mixin(mixin);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
