
<b-form-group
  v-bind="$attrs"
  v-on="$listeners"
  :label-for="labelFor ? labelFor : `input-${label}`"
>
  <template #label>
    <div class="d-flex align-items-center">
      <span>{{ label }}</span>
      <AppQuestionTooltip
        v-if="tooltipTitle"
        :title="tooltipTitle"
        :description="tooltipDescription"
        :width="tooltipWidth"
        style="margin: 0px 5px"
      />
      <span v-if="required" class="text-danger">*</span>
    </div>
  </template>
  <slot></slot>
  <template v-for="(_, name) in $slots" #[name]>
    <slot :name="name"></slot>
  </template>
</b-form-group>
